import AutoridadTemplate from "../subcomponents/AutoridadTemplate"

const Autoridades = () => {
  return (
    // grid content-center justify-center --> container de EnDesarrollo
    <div className="min-h-[90vh] bg-gradient-to-t from-[#5B2479] to-[#342157] text-white grid content-center justify-center">
      <div className="bg-white text-black w-[80vw] p-4 rounded-2xl my-10 ">
        <div className="flex justify-center border-b-2 mb-[13px] ">
          <h1 className="font-bold text-5xl p-14">Autoridades</h1>
        </div>
        <AutoridadTemplate nombre="Claudia Garcia" cargo="Referente Departamental" whatsapp="3584223408" />
        <div className="divider"></div>
        <AutoridadTemplate nombre="Pablo Pereyra" cargo="Referente de la Ciudad de Rio Cuarto" />
        <div className="divider"></div>
        <AutoridadTemplate nombre="Mateo Dambolena" cargo="Referente de Achiras" whatsapp="3585101214" />
        <div className="divider"></div>
        <AutoridadTemplate nombre="Carlos Lisa" cargo="Referente de Alpa Corral" whatsapp="3584293142" />
        <div className="divider"></div>
        <AutoridadTemplate nombre="Diego Ramos" cargo="Referente de Las Higueras" mb="si" whatsapp="3585011595" />
        <AutoridadTemplate nombre="Marisa San Martin" cargo="Referente de Las Higueras" />
        <div className="divider"></div>
        <AutoridadTemplate nombre="Nahum Duarte" cargo="Referente de Coronel Moldes" mb="si" />
        <AutoridadTemplate nombre="Diego Calderón" cargo="Referente de Coronel Moldes" />
        <div className="divider"></div>
        <AutoridadTemplate nombre="Santiago Lombardi" cargo="Referente de Vicuña Mackenna" mb="si" />
        <AutoridadTemplate nombre="Julian Ferreira" cargo="Referente de Vicuña Mackenna" />
        <div className="divider"></div>
        <AutoridadTemplate nombre="Daniel Renaudo" cargo="Referente de Las Verientes" />
        <div className="divider"></div>
        <AutoridadTemplate nombre="Diego Calderón" cargo="Referente de Sampacho" />
        <div className="divider"></div>
        <AutoridadTemplate nombre="Raúl Bértola" cargo="Referente de Alcira Gigena" />
        <div className="divider"></div>
        <AutoridadTemplate nombre="Rita Funes" cargo="Referente de San Basilio" />
      </div>
    </div>
  )
}
export default Autoridades