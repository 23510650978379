import { useState, useEffect } from 'react';

const Localidades = () => {
  const [selectedLocalidad, setSelectedLocalidad] = useState("Río Cuarto");
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = `${process.env.PUBLIC_URL}/img/fondo5.jpg`;

    img.onload = () => {
      setBackgroundLoaded(true);
    };

    return () => {
      img.onload = null;
    };
  }, []);

  const handleChange = (e) => {
    setSelectedLocalidad(e.target.value);
  };

  const localidadesData = {
    "Río Cuarto": {
      description:
        "Estamos presentes en la ciudad de Río Cuarto desde la gesta de la campaña presidencial de Javier Milei, somos riocuartenses con la mirada puesta en el futuro de la ciudad y del pais.",
      referentes: ["Claudia Garcia", "Pablo Pereyra"],
      contacto: "Riocuartolalibertadavanza@gmail.com",
    },

    "Adelia Maria": {
      description:
        "Estamos presentes en la localidad de Adelia Maria desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais.",
      referentes: ["Javier López","Julio Meidardo"],
      contacto: "Lalibertadavanzaadeliamaria@gmail.com",
    },

    "Achiras": {
      description:
        "Estamos presentes en la localidad de Achiras desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Mateo Dambolena"],
      contacto: "Lalibertadavanzaachiras@gmail.com",
    },
    
    "Alcira Gigena": {
      description:
        "Estamos presentes en la localidad de Alcira Gigena desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais.",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
    
    "Alpa Corral": {
      description:
        "Estamos presentes en la localidad de Alpa Corral desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Lalibertadavanzaalpacorral@gmail.com",
    },
    
    "Baigorria": {
      description:
        "Estamos presentes en la localidad de Balgorria desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },

    "Berrotarán": {
      description:
        "Estamos presentes en la localidad de Berrotarán desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Lalibertadavanzaberrotaran@gmail.com",
    },

    "Bulnes": {
      description:
        "Estamos presentes en la localidad de Bulnes desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
        
    "Chaján": {
      description:
        "Estamos presentes en la localidad de Chajan desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Santiago Vietti"],
      contacto: "Proximamente",
    },
        
    "Chucul": {
      description:
        "Estamos presentes en la localidad de Chucul desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
        
    "Coronel Moldes": {
      description:
        "Estamos presentes en la localidad de Coronel Moldes desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Rita Funes","Yanina González","Nahum Duarte"],
      contacto: "Lalibertadavanzacoronelmoldes@gmail.com",
    },
        
    "Elena": {
      description:
        "Estamos presentes en la localidad de Elena desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
        
    "Espinillo": {
      description:
        "Estamos presentes en la localidad de Espinillo desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
        
    "Holmberg": {
      description:
        "Estamos presentes en la localidad de Holmberg desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Daniel Renaudo"],
      contacto: "Lalibertadavanzaholmberg@gmail.com",
    },
        
    "La Cautiva": {
      description:
        "Estamos presentes en la localidad de La Cautiva desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },

        
    "La Carolina": {
      description:
        "Estamos presentes en la localidad de La Carolina desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais.",
      referentes: ["Sin referente"],
      contacto: "Lalibertadavanzalacarolina@gmail.com",
    },

        
    "La Gilda": {
      description:
        "Estamos presentes en la localidad de La Gilda desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
    
    "La Invernada": {
      description:
        "Estamos presentes en la localidad de La Invernada desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
    
    "Las Acequias": {
      description:
        "Estamos presentes en la localidad de Las Acequias desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Diego Brignone"],
      contacto: "Proximamente",
    },
    
    "Las Albahacas": {
      description:
        "Estamos presentes en la localidad de Las Albahacas desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
    
    "Las Cañitas": {
      description:
        "Estamos presentes en la localidad de Las Cañitas desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais.",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
    
    "Las Higueras": {
      description:
        "Estamos presentes en la localidad de Las Higueras desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais.",
      referentes: ["Marisa San Martin","Diego Ramos"],
      contacto: "Lalibertadavanzalashigueras@gmail.com",
    },
    
    "Las Peñas": {
      description:
        "Estamos presentes en la localidad de Las Peñas desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais.",
      referentes: ["Sin referentes"],
      contacto: "Proximamente",
    },
    
    "Las Vertientes": {
      description:
        "Estamos presentes en la localidad de Las Vertientes desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Daniel Renaudo"],
      contacto: "Proximamente",
    },
    
    "Los Cuatro Vientos": {
      description:
        "Estamos presentes en el Paraje Los Cuatro Vientos desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
    
    "Malena": {
      description:
        "Estamos presentes en la localidad de Malena desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
    
    "Monte De Los Gauchos": {
      description:
        "Estamos presentes en la localidad de Monte de los Gauchos desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
    
    "Sampacho": {
      description:
        "Estamos presentes en la localidad de Sampacho desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais.",
      referentes: ["Rita Funes", "Daniel Renaudo"],
      contacto: "Lalibertadavanzasampacho@gmail.com",
    },
    
    "San Basilio": {
      description:
        "Estamos presentes en la localidad de San Basilio desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Rita Funes"],
      contacto: "Proximamente",
    },
    
    "San Ambrosio": {
      description:
        "Estamos presentes en la localidad de San Ambrosio desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
    
    "Suco": {
      description:
        "Estamos presentes en la localidad de Suco desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
    
    "Tosquita": {
      description:
        "Estamos presentes en la localidad de Tosquita desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Pedro Schmitt"],
      contacto: "Proximamente",
    },

    "Vicuña Mackenna": {
      description:
        "Desde que Javier Milei comenzó su campaña presidencial fuimos varios quienes lo acompañamos desde Mackenna, fue en la fiscalizacion de las elecciones donde nos encontramos defendiendo la propuesta libertaria.\nLuego tuvimos la oportunidad de formar en conjunto con varios referentes del Departamento de Río Cuarto La Libertad Avanza como partido en nuestra provincia, con el Diputado Bornoroni como principal referente y con el apoyo del armado nacional del partido.",
      referentes: ["Santiago Lombardi"],
      contacto: "LLAVMack@gmail.com",
      telefono: "(3583) 45-0330"
    },
    
    "Villa El Chacay": {
      description:
        "Estamos presentes en la localidad de Villa El Chacay desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Lalibertadavanzavillaelchacay@gmail.com",
    },
    
    "Washington": {
      description:
        "Estamos presentes en la localidad de Washington desde la gesta de la campaña presidencial de Javier Milei, somos ciudadanos con la mirada puesta en el futuro de la localidad y del pais. ",
      referentes: ["Sin referente"],
      contacto: "Proximamente",
    },
  };

  return (
    <div
      className="min-h-[90vh] text-white grid content-center justify-center"
      style={{
        backgroundImage:
        backgroundLoaded ? `url(${process.env.PUBLIC_URL}/img/fondo5.jpg)` : `url(${process.env.PUBLIC_URL}/img/fondo5lazy.jpg)`, // Cambia 'placeholder.jpg' por la ruta de tu imagen de baja resolución
      }}
    >
      <div className="bg-white text-black  w-[80vw] p-8 rounded-2xl my-10 mx-20">
        <div className="flex justify-center ">
          <h1 className="font-bold text-5xl p-14 text-black">Localidades</h1>
        </div>
        <div className="flex px-4">
        <div className="divider w-[-webkit-fill-available] pr-4 max-sm:hidden "></div>

          <select value={selectedLocalidad} onChange={handleChange} className="select select-bordered w-full max-w-xs">
            <option selected>Río Cuarto</option>
            <option>Adelia Maria</option>
            <option>Achiras</option>
            <option>Alcira Gigena</option>
            <option>Alpa Corral</option>
            <option>Baigorria</option>
            <option>Berrotarán</option>
            <option>Bulnes</option>
            <option>Chaján</option>
            <option>Chucul</option>
            <option>Coronel Moldes</option>
            <option>Elena</option>
            <option>Espinillo</option>
            <option>Holmberg</option>
            <option>La Cautiva</option>
            <option>La Carolina</option>
            <option>La Gilda</option>
            <option>La Invernada</option>
            <option>Las Acequias</option>
            <option>Las Albahacas</option>
            <option>Las Cañitas</option>
            <option>Las Higueras</option>
            <option>Las Peñas</option>
            <option>Las Vertientes</option>
            <option>Los Cuatro Vientos</option>
            <option>Malena</option>
            <option>Monte De Los Gauchos</option>
            <option>Sampacho</option>
            <option>San Basilio</option>
            <option>San Ambrosio</option>
            <option>Suco</option>
            <option>Tosquita</option>
            <option>Vicuña Mackenna</option>
            <option>Villa El Chacay</option>
            <option>Washington</option>
          </select>
        </div>
        <div className="flex">
        
          <div className=" max-w-[-webkit-fill-available] p-4 text-black">
            <h2 className="pb-2 text-md font-semibold">{selectedLocalidad}</h2>
            <p className="pb-5" dangerouslySetInnerHTML={{ __html: localidadesData[selectedLocalidad].description.replace(/\n/g, "<br>") }}></p>
            <p className="pb-2 font-semibold">Referentes:</p>
            {localidadesData[selectedLocalidad].referentes.map((referente, index) => (
              <p key={index}>{referente}</p>
            ))}
            <p className="pt-2 font-semibold">Contacto Local:</p>
            <a href={`mailto:${localidadesData[selectedLocalidad].contacto}`} className='flex max-w-[-webkit-fill-available] overflow-auto'>
              Correo: {localidadesData[selectedLocalidad].contacto}
            </a>
            { localidadesData[selectedLocalidad].telefono ? 
            <>
            <p>Telefono: {localidadesData[selectedLocalidad].telefono}</p>
            </>
            : null }
            <p className="py-2 font-semibold">Contacto Departamental:</p>
            <a href="mailto:Riocuartolalibertadavanza@gmail.com" className='flex max-w-[-webkit-fill-available] overflow-auto'>Correo: RioCuartoLaLibertadAvanza@gmail.com</a>
            <p>Telefono: (358) 517-8587</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Localidades;
