import { Link } from "react-router-dom"
import StatsTable from "../subcomponents/StatsTable"

const Stats = () => {
  return (
    <div className="bg-[#484873] min-h-[90vh] grid items-center">
      <div className="grid items-center justify-items-center">
        <h1 className="text-4xl font-bold text-white mx-[18px] mb-4">Respondieron el formulario:</h1>
        <StatsTable/>
        <div className="mt-4 w-[90vw]">
          <Link to='/' className="btn">Ir a la pagina del partido</Link>
        </div>
      </div>
      
    </div>
  )
}
export default Stats