import React, { useEffect, useState } from 'react';

const Home = () => {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = `${process.env.PUBLIC_URL}/img/fondo2.jpg`;

    img.onload = () => {
      setBackgroundLoaded(true);
    };

    return () => {
      img.onload = null;
    };
  }, []);

  return (
    <div id="home">
      <div
        className={`hero min-h-[85vh] max-sm:max-h-full ${backgroundLoaded ? 'bg-loaded' : ''}`}
        style={{
          backgroundImage:
          backgroundLoaded ? `url(${process.env.PUBLIC_URL}/img/fondo6.png)` : `url(${process.env.PUBLIC_URL}/img/fondo1lazy.jpg)`, 
        }}
      >
        <div className="hero-overlay bg-transparent w-full"></div>
        <div className="hero-content text-center text-white">
          <div className="max-w-screen-lg">
            <h1 className="mb-5 text-7xl font-bold">Somos La Libertad Avanza</h1>
            <p className="mb-5 text-xl px-9">
              Somos el partido de las ideas de la libertad en Río Cuarto, creemos que un futuro libre y próspero es posible, pero ese futuro es solo posible si ese futuro es liberal. ¡Hagámoslo realidad!
            </p>
            <a href="#sumate"  className="btn text-white font-bold bg-[#e3602c] hover:bg-[#ff804e] border-[#e3602c] hover:border-[#ff804e]">¡SUMATE!</a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
