const Links = () => {
  return (
    <div className="bg-[#404078] text-white h-[80vh]">
      <div className=" flex flex-col p-7 items-center">
        <h1 className="pb-2 text-2xl font-semibold">NUESTRAS REDES</h1>
        <a href="wa.me" className="max-w-[-webkit-fill-available]">
          <div className="card w-96  shadow-xl image-full max-w-fit pb-2">
            <figure>
              <img
                src="https://daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg"
                alt="Shoes"
              />
            </figure>
            <div className="card-body">
              <h2 className="card-title">AFILIATE</h2>
              {/* <p>!Hace click aca y afiliate!</p> */}
              <div className="card-actions justify-end">
                {/* <button className="btn btn-primary">!Afiliate!</button> */}
              </div>
            </div>
          </div>
        </a>
        <a href="https://www.instagram.com/lalibertadavanzariocuarto/" className="btn w-[-webkit-fill-available] mb-2">
          <div className="">Instagram</div>
        </a>
        <a href="https://wa.me/message/II7TV3KRKQHSB1/" className="btn w-[-webkit-fill-available] mb-2">
          Whatsapp
        </a>
        <a href="https://www.facebook.com/profile.php?id=61552388917177" className="btn w-[-webkit-fill-available] mb-2">
          Facebook Perfil
        </a>
        <a href="https://www.tiktok.com/@llariocuarto" className="btn w-[-webkit-fill-available] mb-2">
          Tiktok
        </a>
        <a href="https://www.youtube.com/@LaLibertadAvanzaRiocuarto" className="btn w-[-webkit-fill-available] mb-2">
          Youtube
        </a>
      </div>
    </div>
  );
};
export default Links;
