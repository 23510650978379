import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation()
  return (
    <>
    {location.pathname === '/Status' ? null : 
      <>
      <div className="navbar bg-base-100 sticky top-0 z-[1] shadow-md">
        <div className="flex-1">
          <Link to="/" className="btn btn-link text-xl">
            <img
              src={`${process.env.PUBLIC_URL}/img/LogoRCPortrait.png`}
              className="max-h-[-webkit-fill-available]"
              alt=""
            />
          </Link>
        </div>
        <div className="flex-none">
          <ul className="menu menu-horizontal px-1 font-bold">
            <li className="max-md:hidden">
              <Link to="/Juventud" className="jinky text-4xl">Juventud</Link>
            </li>

            {/* <li className="max-md:hidden">
              <Link to="/NuestraHistoria" className="text-xl navHeight">Nuestra Historia</Link>
            </li> */}

            <li className="max-md:hidden">
              <Link to="/Localidades" className="text-xl navHeight">Localidades</Link>
            </li>

            {/* <li className="max-md:hidden">
              <a href="#sumate">¡Sumate!</a>
            </li> */}

            {/* //! Seccion deshabilitada }
            {/* <li className="max-md:hidden">
              <Link to='/Contacto'>Contacto</Link>
            </li> */}

            {/* //! Seccion deshabilitada }
            {/* <li className="max-md:hidden">
              <Link to="/Login">Login</Link>
            </li> */}

            <div className="flex-none md:hidden">
              <label
                htmlFor="my-drawer-4"
                aria-label="open sidebar"
                className="btn btn-square btn-ghost"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block w-6 h-6 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </label>
            </div>
            <div className="drawer drawer-end">
              <input
                id="my-drawer-4"
                type="checkbox"
                className="drawer-toggle"
              />
              <div className="drawer-content">{/* Page content here */}</div>
              <div className="drawer-side">
                <label
                  htmlFor="my-drawer-4"
                  aria-label="close sidebar"
                  className="drawer-overlay"
                ></label>
                <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
                  {/* Sidebar content here */}
                  {/* <li>
                    <Link to="/Historia">Nuestra Historia</Link>
                  </li> */}

                  <li >
                    <Link to="/Localidades">Localidades</Link>
                  </li>

                  <li >
                    <Link to="/Juventud" className="jinky" style={{fontSize: `36px`}}>Juventud</Link>
                  </li>

                  {/* <li >
                    <Link to="/Sumate">¡Sumate!</Link>
                  </li> */}

                  {/* <li >
                    <Link to="/Contacto">Contacto</Link>
                  </li> */}

                  {/* <li >
                    <Link to="/Login">Login</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* <li className="md:hidden">
              <details>
                <summary>Secciones</summary>
                <ul className="p-2 bg-base-100 rounded-t-none">
                  <li>
                    <Link to="/Historia">Nuestra Historia</Link>
                  </li>
                  
                  <li>
                    <Link to="/Localidades">Localidades</Link>
                  </li>

                  <li>
                    <Link to="/Sumate">¡Sumate!</Link>
                  </li>

                  <li>
                    <Link to="/Contacto">Contacto</Link>
                  </li>

                  <li>
                    <Link to="/Login">Login</Link>
                  </li>
                </ul>
              </details>
            </li> */}
          </ul>
        </div>
      </div>
      
      </>
      }
    </>
  );
};
export default Navbar;
