import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// import { BrowserRouter as Router} from 'react-router-dom';
import Sumate from './components/pages/Sumate';
import Contacto from './components/pages/Contacto';
import NuestraHistoria from './components/pages/NuestraHistoria';
import Autoridades from './components/pages/Autoridades';
// import PageNotFound from './components/pages/PageNotFound';
// import ScrollToTop from './ScrollToTop';
// import Login from './components/pages/Login';
import Localidades from './components/pages/Localidades';
import Links from './components/pages/Links';
import Juventud from './components/pages/Juventud';
import Stats from './components/pages/Stats';
import PageNotFound from './components/pages/PageNotFound';

function App() {
  const Inicio = () => {
    return (
      <>
        <Home/>
        <Sumate/>
      </>
    )
  }
  return (
    <>
      <Router>
        <Navbar/>
        <Routes>
          <Route path='/Contacto' element={<Contacto/>} />
          <Route path='/Localidades' element={ <Localidades /> } />
          <Route path='/NuestraHistoria' element={ <NuestraHistoria /> } />
          <Route path='/Autoridades' element={ <Autoridades /> } />
          <Route path='/Juventud' element={ <Juventud /> } />
          <Route path='/Links' element={ <Links /> } />
          <Route path='/Status' element={ <Stats/> } />
          <Route exact path='/' element={ <Inicio/>} />
          <Route path='/*' element={<PageNotFound/>} />
        </Routes>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
