import { app } from "./config";
import { getFirestore, collection, addDoc, query, getDocs } from "firebase/firestore";

const db = getFirestore(app)

async function sendSumate  (nombre, apellido, correo, telefono, localidad) {
  try {
    const docRef = await addDoc(collection(db, "sumate"), {
      nombre: nombre,
      apellido: apellido,
      correo: correo,
      telefono: telefono,
      localidad: localidad
    });
    console.log("Formulario enviado correctamente con ID: ", docRef.id);
  } catch (e) {
    console.error("Error subiendo el formulario: ", e);
  }
}

const getPersonas = async () => {
  try {
    // Consultar la colección "posts" y ordenar por la marca de tiempo
    const q = query(collection(db, 'sumate'));
    const querySnapshot = await getDocs(q);
    const personas = [];
    querySnapshot.forEach((doc) => {
      // Agregar cada documento a la lista de posts
      personas.push({ id: doc.id, ...doc.data() });
    });
    // console.log(personas);
    return personas; // Devolver la lista de posts
  } catch (e) {
    console.error("Error al obtener los datos: ", e);
    throw e; // Manejo de errores
  }
};

export {sendSumate, getPersonas}