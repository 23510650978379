import { useEffect, useState } from 'react';
const Juventud = () => {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = `${process.env.PUBLIC_URL}/img/FondoJuv.jpg`;

    img.onload = () => {
      setBackgroundLoaded(true);
    };

    return () => {
      img.onload = null;
    };
  }, []);

  return (
    <div
      className="h-[85vh] text-white bg-cover bg-center flex justify-center"
      style={{
        backgroundImage:
        backgroundLoaded ? `url(${process.env.PUBLIC_URL}/img/FondoJuv.jpg)` : `url(${process.env.PUBLIC_URL}/img/FondoJuvlazy.jpg)`, 
      }}
    >
      <div className="flex flex-col items-center max-w-[42rem]">
        <h1 className=" jinky-juv ">Juventud</h1>
        <div className="text-white px-[20px] font-semibold text-lg">
          <p className="mb-2 ">
            Somos la juventud que cree que nuestro país tiene un futuro brillante por delante, pero para poder alcanzarlo debemos construirlo sentando las bases que nos permitan crecer como ciudadanos argentinos.
          </p>
          <p>
            Para nosotros, La Libertad Avanza encarna la revolución política que deseamos, y es por esto que decidimos ser parte de este espacio, defendiendo los valores e ideas que harán de Argentina un país grande y próspero.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Juventud;
