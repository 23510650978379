import { useState, useEffect } from "react";
import { getPersonas } from "../../firebase/firestore";

const StatsTable = () => {
  const [personas, setPersonas] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const personasData = await getPersonas();
        setPersonas(personasData);
      } catch (e) {
        console.log("error: ", e);
      }
    };

    fetchData();
  }, []); // Agrega un arreglo vacío para que useEffect solo se ejecute una vez al montar el componente

  return (
    <div className="bg-white rounded-md max-w-[90vw] mx-[10px]">
      <div className="overflow-x-auto">
        <table className="table table-xs">
          <thead>
            <tr>
              <th></th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Localidad</th>
              <th>Celular</th>
              <th>Mail</th>
            </tr>
          </thead>
          <tbody>
            {personas.map((persona, index) => (
              <tr key={index + 1}> {/* Usa el índice del mapeo más 1 como key */}
                <th>{index + 1}</th> {/* Incrementa el índice del mapeo más 1 */}
                <td>{persona.nombre}</td>
                <td>{persona.apellido}</td>
                <td>{persona.localidad}</td> {/* Corrige "persona.apellido" a "persona.localidad" */}
                <td>{persona.telefono}</td> {/* Cambia "persona.telefono" a "persona.celular" */}
                <td>{persona.correo}</td> {/* Cambia "persona.correo" a "persona.mail" */}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Localidad</th>
              <th>Celular</th>
              <th>Mail</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
export default StatsTable;
