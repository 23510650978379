import React, { useState, useEffect } from "react";
import { sendSumate } from "../../firebase/firestore";

const Sumate = () => {
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [showToastSend, setShowToastSend] = useState(false);
  const [showToastError, setShowToastError] = useState(false);


  useEffect(() => {
    let timeoutId;
    if (showToastSend || showToastError) {
      timeoutId = setTimeout(() => {
        setShowToastSend(false);
        setShowToastError(false);
      }, 4000);
    }
    return () => clearTimeout(timeoutId);
  }, [showToastSend, showToastError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      nombre.trim() === "" ||
      apellido.trim() === "" ||
      correo.trim() === "" ||
      telefono.trim() === "" ||
      localidad.trim() === ""
    ) {
      setShowToastError(false);
      setShowToastError(true);
      return;
    }
    await sendSumate(nombre, apellido, correo, telefono, localidad);
    setShowToastSend(false);
    setShowToastSend(true);
    setNombre("");
    setApellido("");
    setCorreo("");
    setTelefono("");
    setLocalidad("");
  };

  return (
    <div
      id="sumate"
      className="min-h-[90vh] text-black grid content-center justify-center bg-cover bg-local bg-no-repeat"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/milei.jpg)`,
        backgroundPosition: "center 0px",
      }}
    >
      <div className="contenedorSumate flex max-lg:flex-col-reverse p-20 w-screen">
        <div className="min-md:max-w-80 p-8 border-solid border-white border-4 rounded-xl my-4 bg-white drop-shadow-2xl min-md:min-w-[483px] w-[-webkit-fill-available] ">
          <div className="grid justify-center">
            <h2 className="font-bold text-5xl">¡Sumate!</h2>
          </div>
          <div className="divider"></div>
          <form
            className="grid grid-cols-2 max-sm:grid-cols-1"
            onSubmit={handleSubmit}
          >
            <div className="col1 sm:pr-1.5">
              <label className="form-control w-full ">
                <div className="label">
                  <span className="label-text text-black block-size-1">
                    Nombre
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Tu nombre"
                  className="input input-bordered w-full "
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </label>

              <label className="form-control w-full ">
                <div className="label">
                  <span className="label-text text-black block-size-1">
                    Apellido
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Tu apellido"
                  className="input input-bordered w-full "
                  value={apellido}
                  onChange={(e) => setApellido(e.target.value)}
                />
              </label>

              <label className="form-control w-full ">
                <div className="label">
                  <span className="label-text text-black block-size-1">
                    Teléfono
                  </span>
                </div>
                <input
                  type="tel"
                  placeholder="358XXXXXXX"
                  className="input input-bordered w-full "
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                />
              </label>
            </div>
            <div className="col2 sm:pl-1.5">
              <label className="form-control w-full ">
                <div className="label">
                  <span className="label-text text-black block-size-1">
                    Correo Electronico
                  </span>
                </div>
                <input
                  type="email"
                  placeholder="Tu@mail.com"
                  className="input input-bordered w-full "
                  value={correo}
                  onChange={(e) => setCorreo(e.target.value)}
                />
              </label>

              <label className="form-control w-full ">
                <div className="label">
                  <span className="label-text text-black block-size-1">
                    Localidad
                  </span>
                </div>
                <select
                  className="select select-bordered text-gray-500 "
                  onChange={(e) => setLocalidad(e.target.value)}
                  
                >
                  <option disabled selected>
                    Selecciona tu localidad
                  </option>
                  <option>Rio Cuarto</option>
                  <option>Adelia Maria</option>
                  <option>Achiras</option>
                  <option>Alcira Gigena</option>
                  <option>Alpa Corral</option>
                  <option>Baigorria</option>
                  <option>Berrotarán</option>
                  <option>Bulnes</option>
                  <option>Chaján</option>
                  <option>Chucul</option>
                  <option>Coronel Moldes</option>
                  <option>Elena</option>
                  <option>Espinillo</option>
                  <option>Holmberg</option>
                  <option>La Cautiva</option>
                  <option>La Carolina</option>
                  <option>La Gilda</option>
                  <option>La Invernada</option>
                  <option>Las Acequias</option>
                  <option>Las Albahacas</option>
                  <option>Las Cañitas</option>
                  <option>Las Higueras</option>
                  <option>Las Peñas</option>
                  <option>Las Vertientes</option>
                  <option>Los Cuatro Vientos</option>
                  <option>Malena</option>
                  <option>Monte De Los Gauchos</option>
                  <option>Sampacho</option>
                  <option>San Basilio</option>
                  <option>San Ambrosio</option>
                  <option>Suco</option>
                  <option>Tosquita</option>
                  <option>Vicuña Mackenna</option>
                  <option>Villa El Chacay</option>
                  <option>Washington</option>
                </select>
              </label>
              <button className="btn text-white bg-[#6c4c99] border-[#6c4c99] hover:bg-[#8a61c4] hover:border-[#8a61c4] sm:mt-[30px] max-sm:mt-4 w-full">
                Enviar formulario
              </button>
            </div>
          </form>
        </div>
        <div className="max-md:hidden ">
          <h2 className="text-white font-semibold text-2xl ml-6 drop-shadow-2xl mt-2 max-lg:ml-0 max-lg:text-3xl max-lg:mb-6">
            Sumate al equipo de La Libertad Avanza, y sé parte del cambio que necesita nuestro país. ¡Vos también podés luchar por las ideas de la libertad!
          </h2>
        </div>
      </div>
      {showToastSend && (
        <div className="toast toast-top toast-end top-20">
          <div role="alert" className="alert alert-success flex">
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            <span>¡Formulario enviado con éxito!</span>
          </div>
        </div>
      )}
      {showToastError && (
        <div className="toast toast-top toast-end top-20">
          <div role="alert" className="alert alert-error flex">
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            <span>¡Rellena todos los campos!</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default Sumate;
