import { Link } from "react-router-dom"

const PageNotFound = () => {
  return (
    <div className="min-h-[90vh] bg-gradient-to-t from-[#5B2479] to-[#342157] text-white grid content-center justify-center">
      <p className="font-bold text-5xl mb-8 mx-4">Parece que la pagina que buscas no existe</p>
      <div className="grid content-center justify-center">
        <Link to="/" className="btn btn-info">Ir al inicio</Link>
      </div>
    </div>
  )
}
export default PageNotFound