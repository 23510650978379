import EnDesarrollo from "./EnDesarrollo"

const NuestraHistoria = () => {
  return (
    // grid content-center justify-center --> container de EnDesarrollo
    <div className="min-h-[90vh] bg-gradient-to-t from-[#5B2479] to-[#342157] text-white grid content-center justify-center">
      <EnDesarrollo/>
    </div>
  )
}
export default NuestraHistoria