// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_7JvC2YLILvNLtqwhIKVOOpqRNKbGgSg",
  authDomain: "la-libertad-avanza-rio-cuarto.firebaseapp.com",
  projectId: "la-libertad-avanza-rio-cuarto",
  storageBucket: "la-libertad-avanza-rio-cuarto.appspot.com",
  messagingSenderId: "127976148660",
  appId: "1:127976148660:web:ae2bcc71f5efc83eaf51e1",
  measurementId: "G-Q1SBGEEN8T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export {app}